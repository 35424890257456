import React from 'react';
import { reduxForm } from 'redux-form';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import {
  Button, Input, FormGroupList, 
} from 'tyb';
import { TablePopConfirm } from 'ucode-components';
import enums from '@/assets/enum.json';
import { NewSelect  } from 'ucode-components';
import { FormGroupField } from 'tyb';
const { authorizerConfigs } = iceStarkData.store.get('commonAction')?.wechat;
const { setCurrentStep, setCreateStep } = iceStarkData.store.get('commonAction')?.codePrintingConfig;
const { loadSelectList } = iceStarkData.store.get('commonAction')?.codeSource;
const { domainConfigsByCodeSourceType } = iceStarkData.store.get('commonAction')?.domainConfigs;
import * as validation from './validation';
import { trims } from 'ucode-utils';
const setting = (props) => {

  if (props.showValue.combinationTagType == 'SAME_LEVEL_TAG') {
    return (
      <div>
        <p style={{ fontSize: '12px', marginBottom: 0 }}>第1个码规格-{props.showValue.name1List.enterpriseDomain.name}</p>
        <p style={{ fontSize: '12px', marginBottom: 0 }}>第2个码规格-{props.showValue.name2List.enterpriseDomain.name}</p>
        {props.showValue.conditionKeys == 3 ? <p style={{ fontSize: '12px', marginBottom: 0 }}>第3个码规格-{props.showValue.name3List.enterpriseDomain.name}</p> : ''}
      </div>
    )
  }
  else {
    return (
      <div>
        <p style={{ fontSize: '12px', marginBottom: 0 }}>箱码-{props.showValue.name2_1List.enterpriseDomain.name}</p>
        <p style={{ fontSize: '12px', marginBottom: 0 }}>{props.showValue.batchs == 1 ? '瓶规格-' : '第1个码规格-'}{props.showValue.name2_2List.enterpriseDomain.name}</p>
        {props.showValue.batchs >= 2 ? <p style={{ fontSize: '12px', marginBottom: 0 }}>第2个码规格-{props.showValue.name2_3List.enterpriseDomain.name}</p> : ''}
        {props.showValue.batchs >= 3 ? <p style={{ fontSize: '12px', marginBottom: 0 }}>第3个码规格-{props.showValue.name2_4List.enterpriseDomain.name}</p> : ''}
      </div>
    )
  }


}
class CreateCodePrintingConfigStep1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordComplexitySelect: Object.keys(enums.passwordComplexity).map(item => { return { value: item, text: enums.passwordComplexity[item].name } }),
      printFileFormatSelect: [
        {
          text: 'CSV',
          value: 'CSV',
        },
        {
          text: 'TXT',
          value: 'TXT',
        },
      ],
      registrationMethodSelect: [
        {
          text: '线上录入',
          value: 'ONLINE_INPUT',
        },
        {
          text: '线下采集',
          value: 'OFFLINE_ACQUISITION',
        },
      ],
      registrationMethodSelect2: [
        {
          text: '线上录入',
          value: 'ONLINE_INPUT',
        },
      ],
    }
  }

  componentDidMount() {
    const { step0, domainConfigsByEseId, loadSelectList, authorizerConfigs, initialValues } = this.props;
    console.log(initialValues)
    if (initialValues.encryption == "COMMON") {
      this.setState({
        passwordComplexitySelect: [
          { value: "GENERAL_SECRECY", text: "一般保密度8位纯数字" },
          { value: "HIGHLY_SECRECY", text: "中保密度36位字母数字" }
        ]
      })

    } else if (initialValues.encryption == "IPC") {
      this.setState({
        passwordComplexitySelect: [{ value: "IPC_SECRECY", text: "高保密度工厂授权证书" }]
      })
    }

    const codeSourceType = step0.codeSourceType;
    if (this.props.zuhe) {
    }
    else {
      loadSelectList(codeSourceType);
      domainConfigsByEseId({ codeSourceType });
    }
    if (codeSourceType === 'WECHART_CODE') {
      authorizerConfigs('MINI_PROGRAM');
      authorizerConfigs('WECHAT_SCRIPTION');

    }
  }

  perStep = () => {
    const { setCreateStep, formValues } = this.props;
    setCreateStep({ step: 1, values: trims(formValues.values) });
    this.props.setCurrentStep(0);
  }

  next = (values) => {
    console.log(values.passwordComplexity)
    //  console.log(this.props.formValues)
    //  console.log({...this.props.step2,passwordComplexity:values.passwordComplexity})
    this.props.setCreateStep({ step: 1, values: trims(values) });
    // console.log(this.props.step2)
    this.props.setCreateStep({
      step: 2, values: {
        configWorkflowTemplateDTOs: [{
          workflowNodes: [
            {
              nodeCategory: 'USER',
              nodeType: 'PROCESS_START',
            },
            {
              nodeCategory: 'USER',
              nodeType: 'PROCESS_AUDIT',
            },
            {
              nodeCategory: 'USER',
              nodeType: 'PACKET_RECEIVER',
            },
            {
              nodeCategory: 'USER',
              nodeType: 'PASSWORD_RECEIVER',
            },
          ]
        }], passwordComplexity: values.passwordComplexity
      }
    });
    this.props.setCurrentStep(2);
  }

  canCancel = () => {
    this.setState({
      confirmStatus: true
    });
  }

  cancel = () => {
    this.props.history.goBack();
  }

  requiredByInput = validation.required('请填写')

  requiredBySelect = validation.required('请选择')

  maxLength = validation.maxLength(30)


  render() {
    const { step0, domainConfigsByEseIdSelect, handleSubmit, change, codeSourceSelectList, miniSelections, wechatSelections, tagstatusSelection } = this.props;

    const { passwordComplexitySelect, printFileFormatSelect, registrationMethodSelect, registrationMethodSelect2 } = this.state;
    const noWechatCodeSource = step0.codeSourceType !== 'WECHART_CODE';
    return (
      <div className="step1">
        <FormGroupList>
          <FormGroupField
            name="name"
            required
            label="码印刷规格名称"
            component={Input}
            validate={[this.requiredByInput, this.maxLength]}
          >
            <div className="extraText"> 单个企业下名称唯一，支持中文、英文字母、中划线和下划线，长度不超过30个字符</div>
          </FormGroupField>

          <FormGroupField
            name="code"
            label="码印刷规格编码"
            component={Input}
            validate={[this.maxLength]}
          >
          </FormGroupField>

          {
            step0.codeSourceType && step0.codeSourceType !== 'PLATFORM_STANDARD' &&
            <FormGroupField
              name="codeSourceId"
              required
              label="码源"
              required
              style={{width:330}}
              component={NewSelect}
              // placeholder={'请选择'}
              list={codeSourceSelectList}
              validate={[this.requiredBySelect]}

            />
          }

          {
            step0.codeSourceType && step0.codeSourceType !== 'WECHART_CODE' &&
            <FormGroupField
              name="eseDomainId"
              required
              label="码域名"
              style={{width:330}}
              component={NewSelect}
              placeholder={'请选择'}
              list={domainConfigsByEseIdSelect}
              validate={[this.requiredBySelect]}

            />
          }


          {
            step0.combinationTagType && step0.combinationTagType !== 'SINGLE_TAG' &&
            <FormGroupField
              name="name2"
              label="码域名"
              component={setting}
              showValue={step0}
            // style={{ width: 190 }}
            />
          }



          {step0.codeSourceType && step0.codeSourceType === 'WECHART_CODE' &&
            <React.Fragment>
              <FormGroupField
                className="wechat-config"
                required
                name="waAppId"
                required
                label="打开小程序"
                style={{width:330}}
                component={NewSelect}
                placeholder={'请选择'}
                list={miniSelections}
                validate={[this.requiredBySelect]}
              >
                <a href="javascript:;" className="more" onClick={() => { this.setState({ showJump: true }) }}>更多</a>
              </FormGroupField>
              {this.state.showJump && <FormGroupField
                name="jumpPath"
                label="扫码跳转路径"
                component={Input}
                validate={[this.requiredByInput]}
              />}
            </React.Fragment>
          }

          {
            step0.codeSourceType && step0.codeSourceType === 'WECHART_CODE' &&
            <FormGroupField
              name="wwAppId"
              required
              label="发码公众号"
              required
              style={{width:330}}
              component={NewSelect}
              placeholder={'请选择'}
              list={wechatSelections}
              validate={[this.requiredBySelect]}
            />
          }


          <FormGroupField
            name="passwordComplexity"
            required
            label="码包解压密码"
            style={{width:330}}
            component={NewSelect}
            placeholder={'请选择'}
            list={passwordComplexitySelect}
            validate={[this.requiredBySelect]}

          />

          <FormGroupField
            name="printFileFormat"
            required
            label="码包中的文件格式"
            style={{width:330}}
            component={NewSelect}
            placeholder={'请选择'}
            list={printFileFormatSelect}
            validate={[this.requiredBySelect]}

          />

          <FormGroupField
            name="registrationMethod"
            required
            label="码信息录入方式"
            style={{width:330}}
            component={NewSelect}
            placeholder={'请选择'}
            list={registrationMethodSelect}
            validate={[this.requiredBySelect]}

          />
          {/* 迎驾贡注释 */}
          {/* <FormGroupField
            name="tagInitStatus"
            label='码初始环节状态'
            placeholder="请选择"
            component={NewSelect}
            list={tagstatusSelection}
          /> */}
        </FormGroupList>

        <div className="line" ></div>
        <div className="button-block">
          <Button className="weak-button" onClick={this.canCancel} >取消</Button> <Button className="weak-button button-margin" onClick={this.perStep} >上一步</Button>  <Button className="button-margin" onClick={handleSubmit(this.next)} >下一步</Button>
          {(this.state.confirmStatus) && <TablePopConfirm
            strongText={'印刷规格暂未完成，确认离开吗？'}
            text={'离开后编辑内容不做保存，请谨慎操作'}
            styles={{ marginTop: -165 }}   // height: 154,
            button={['确定', '取消']}
            onOk={this.cancel}
            onCancel={() => { this.setState({ confirmStatus: false }) }}
          />}
        </div>
      </div>
    )
  }
}

let CreateCodePrintingConfigStep1Form = reduxForm({
  form: 'CreateCodePrintingConfigStep1Form', // a unique identifier for this form
  enableReinitialize: true,
})(CreateCodePrintingConfigStep1)

CreateCodePrintingConfigStep1Form = connect(
  state => ({
    step0: state.codePrintingConfig.createStep0,
    initialValues: state.codePrintingConfig.createStep1,
    domainConfigsByEseIdSelect: state.domainConfigs.domainConfigsByCodeSourceTypeSelect,
    formValues: state.form.CreateCodePrintingConfigStep1Form,
    codeSourceSelectList: state.codeSource.selectList,
    miniSelections: state.wechat.authorizerConfigsSelector,
    wechatSelections: state.wechat.wechatScriptionSelector,
    templateConfigsById: state.codePrintingConfig.templateConfigsById,
  }),
  {
    setCurrentStep,
    domainConfigsByEseId: domainConfigsByCodeSourceType.REQUEST,
    setCreateStep,
    loadSelectList: loadSelectList.REQUEST,
    authorizerConfigs: authorizerConfigs.REQUEST,

  }
)(CreateCodePrintingConfigStep1Form)

CreateCodePrintingConfigStep1Form = withRouter(CreateCodePrintingConfigStep1Form);

export default CreateCodePrintingConfigStep1Form;