import React from 'react';
import { reduxForm } from 'redux-form';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { RadioButtonGroup, RadioGroup, Button, FormGroupList, Input, Tip, notification } from 'tyb';
import { TablePopConfirm } from 'ucode-components';
import { NewSelect  } from 'ucode-components';
import { FormGroupField } from 'tyb';
const { loadTemplateConfigsList, setCreateStep, setCurrentStep, loadSelect } = iceStarkData.store.get('commonAction')?.codePrintingConfig;
const { tagstatusSelections } = iceStarkData.store.get('commonAction')?.links;
import * as validation from './validation';

import { trims } from 'ucode-utils';


let BATCHSFILE = 1;

class FileCount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
    setTimeout(() => {
      const { value } = this.props;
      let batchsfile = 1;
      if (value > 1) {
        batchsfile = BATCHSFILE = 2;
      }
      if (value) {
        this.setState({ batchsfile, batchs: value });
      }

    }, 1);
  }
  // 文件切换
  handleBatchsFileChange = (value) => {
    BATCHSFILE = value;
    this.setState({ batchs: value == 1 ? 1 : 2, batchsfile: value });
    this.props.onChange(value == 1 ? 1 : 2);
    if (value == 2) {
      setTimeout(() => {
        document.getElementById('batchInput').focus();
      }, 100);
    }
  }

  // 文件个数
  handleChange = (e) => {
    let value = e.toString().replace(/[^0-9]/ig, '');
    this.setState({ batchs: value });
    this.props.onChange(value);
  }

  render() {
    const { batchsfile, batchs } = this.state;
    return (
      <div className="file-package">
        <NewSelect
          style={{ width: 160,marginRight:10 }}
          list={[
            {
              text: '单码',
              value: 1,
            },
            {
              text: '同级码',
              value: 2,
            }
          ]}
          value={batchsfile}
          onChange={this.handleBatchsFileChange}
        />
        {batchsfile == 1 ?
          <Input
            {...this.props}
            id="batchInput"
            value={'1个'}
            disabled
          />
          :
          <NewSelect
           
            list={[
              {
                text: '2个',
                value: 2,
              },
              {
                text: '3个',
                value: 3,
              }
            ]}
            {...this.props}
            style={{ width: 160 }}
            id="batchInput"
            value={batchs}
            onChange={this.handleChange}
          />
        }
      </div>
    )
  }
}


class CreateCodePrintingConfigStep0 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTagMat: '',
      selecttype: {
        name1: '',
        name2: '',
      },
      selecttype2: {
        name1: '',
        name2: '',
        name3: '',
      }
    }
  }

  componentDidMount() {
    if (this.props.zuhe) {
      //码状态
      this.props.tagstatusSelections({ data: {}, status: 'ENABLE' });
      this.props.loadSelect()
      if (this.props.step0.selecttype) {
        this.setState({ selecttype: this.props.step0.selecttype })
      }
      if (this.props.step0.selecttype2) {
        this.setState({ selecttype2: this.props.step0.selecttype2 })
      }
      // this.props.step0.tagInitStatus = this.props.step0.tagInitStatus ? this.props.step0.tagInitStatus : 'defalut'
      // const {formValues:{}}=this.props
      // debugger
      // this.props.initialize({ ...values,tagInitStatus: "defalut"})//,combinationTagType:'SAME_LEVEL_TAG',conditionKeys:'2',name1:'',name2:''
    }
    else {
      this.props.loadTemplateConfigsList();
    }

  }


  next = (values) => {
    if (this.props.zuhe) {
      let selecttype
      if (values.combinationTagType == 'SAME_LEVEL_TAG') {//同级码
        selecttype = this.state.selecttype
      } else {
        selecttype = this.state.selecttype2
      }
      if ((selecttype.name1 && selecttype.name1.wwAppId) || (selecttype.name2 && selecttype.name2.wwAppId) || (selecttype.name3 && selecttype.name3.wwAppId)) {
        if (selecttype.name1 && selecttype.name1.wwAppId && selecttype.name2 && selecttype.name2.wwAppId && (selecttype.name1.wwAppId != selecttype.name2.wwAppId)) { return notification.error('码规格和发码公众码不一致') }
        else if (selecttype.name2 && selecttype.name2.wwAppId && selecttype.name3 && selecttype.name3.wwAppId && (selecttype.name2.wwAppId != selecttype.name3.wwAppId)) { return notification.error('码规格和发码公众码不一致') }
        else if (selecttype.name1 && selecttype.name1.wwAppId && selecttype.name3 && selecttype.name3.wwAppId && (selecttype.name1.wwAppId != selecttype.name3.wwAppId)) { return notification.error('码规格和发码公众码不一致') }
      }

      // const list = this.props.selector.filter(key => key.combinationTagType == 'SINGLE_TAG' && key.enterpriseDomain.codeSourceType == 'PLATFORM_STANDARD' || key.enterpriseDomain.codeSourceType == 'PLATFORM_SHORT_CODE').map((item) => { return { value: item.id, text: item.name, historyTagTemplateConfig: item.historyTagTemplateConfig, enterpriseDomain: item.enterpriseDomain } });
      const list = this.props.selector.filter(key => (key.combinationTagType == 'SINGLE_TAG' || key.enterpriseDomain.codeSourceType == 'PLATFORM_SHORT_CODE') && key.enterpriseDomain.codeSourceType != 'CODE_IMPORT').map((item) => { return { value: item.id, text: item.name, historyTagTemplateConfig: item.historyTagTemplateConfig, enterpriseDomain: item.enterpriseDomain } });
     
      this.props.setCreateStep({
        step: 0, values: trims({
          ...values,
          name1List: values.name1 ? list.filter(key => key.value == values.name1)[0] : {},
          name2List: values.name2 ? list.filter(key => key.value == values.name2)[0] : {},
          name3List: values.name3 ? list.filter(key => key.value == values.name3)[0] : {},
          name2_1List: values.name2_1 ? list.filter(key => key.value == values.name2_1)[0] : {},
          name2_2List: values.name2_2 ? list.filter(key => key.value == values.name2_2)[0] : {},
          name2_3List: values.name2_3 ? list.filter(key => key.value == values.name2_3)[0] : {},
          name2_4List: values.name2_4 ? list.filter(key => key.value == values.name2_4)[0] : {},
          selecttype: this.state.selecttype,
          selecttype2: this.state.selecttype2,
        })
      });
      this.props.setCreateStep({ step: 1, values: { ...this.props.step1, tagInitStatus: values.tagInitStatus ? values.tagInitStatus : 'defalut' }, });
      this.props.setCurrentStep(1);
    }
    else {
      const tagMatId = values.tagMatId;
      const templateConfigId = values.templateConfigId;
      const templateConfigsList = this.props.templateConfigsList;
      let templateConfigVersion = '';
      let codeSourceType = '';
      let encryption = ''

      const filter = templateConfigsList.filter(v => v.id === tagMatId);

      // console.log(filter[0].templateConfigList[0],"filterfilterfilterfilterfilter")
      if (filter && filter.length > 0) {
        // encryption =(filter[0].templateConfigList[0].encryption=="COMMON")?"GENERAL_SECRECY":"IPC_SECRECY"
        const templateConfigList = filter[0].templateConfigList;
        codeSourceType = templateConfigList.filter(v => v.id === templateConfigId)[0].codeSourceType;
        templateConfigVersion = filter[0].version;
        filter[0].templateConfigList.forEach(item => {
          if (item.id === templateConfigId) {
            encryption = item.encryption;
          }
        })
      }
      if (encryption == "IPC") {
        this.props.step1.passwordComplexity = "IPC_SECRECY "
      } else if (encryption == "COMMON") {
        this.props.step1.passwordComplexity = "GENERAL_SECRECY"
      } else {
        this.props.step1.passwordComplexity = "GENERAL_SECRECY"
      }

      const codeSourceId = codeSourceType !== this.props.initialValues.codeSourceType ? '' : this.props.step1.codeSourceId;
      this.props.setCreateStep({ step: 0, values: trims({ ...values, templateConfigVersion, codeSourceType, combinationTagType: 'SINGLE_TAG' }) });
      this.props.step1.tagInitStatus = this.props.step1.tagInitStatus ? this.props.step1.tagInitStatus : 'default'
      if (codeSourceType === 'WECHART_CODE') {
        this.props.setCreateStep({ step: 1, values: { ...this.props.step1, eseDomainId: '', codeSourceId, jumpPath: '/pages/scan/scan', encryption: encryption } });
      } else {
        this.props.setCreateStep({ step: 1, values: { ...this.props.step1, waAppId: '', wwAppId: '', codeSourceId, encryption: encryption } });
      }
      this.props.setCurrentStep(1);
    }
  }

  canCancel = () => {
    this.setState({
      confirmStatus: true
    });
  }

  cancel = () => {
    this.props.history.goBack();
  }
  requiredByname1 = validation.required('请选择码规格')
  requiredByname2 = validation.required('请选择箱码规格')
  requiredByTagMatId = validation.required('请选择赋码材料')
  requiredByInput = validation.required('请填写')
  min1 = validation.min(1);
  max99999 = validation.max(9999999);
  requiredByTemplateConfigId = validation.required('请选择印刷方式')

  getDescriptionFile = (templateConfigsObById, tagMatId, templateConfigId) => {
    if (templateConfigsObById && tagMatId !== '' && templateConfigId !== '') {
      const list = templateConfigsObById[tagMatId];
      if (!list) { return '' }
      let result = ''
      list.forEach(v => {
        if (v.id === templateConfigId) {
          result = v.descriptionFile;
        }
      });
      return result;
    }
    return '';
  }



  // 码数量变化，每批码量自动变化
  handleTagCountChange = (data) => {
    data = parseInt(String(data).replace(/[^0-9]/ig, ''));
    let { formValues: { values: { batchs } } } = this.props;
    // const { enterpriseDomain = {} } = tagbatchDetail;
    // // const { codeSourceType } = enterpriseDomain;
    // if (type == 'totalTagCount') {
    //   totalTagCount = parseInt(data || totalTagCount);
    // } else {
    batchs = parseInt(data || batchs);
    // }


    // if (codeSourceType == 'WECHART_CODE') {
    //   let tagCountValue = Math.floor(totalTagCount / 10000 / batchs) * 10000;
    //   if (!tagCountValue) {
    //     tagCountValue = 10000;
    //   }

    //   change('tagCount', tagCountValue);

    // } else if (totalTagCount > 0 && totalTagCount <= 40000000 && batchs <= totalTagCount) {    // 申请码量大于1，小于等于20000000，文件个数小于码量
    //   change('tagCount', parseInt(totalTagCount / batchs));
    // }
  }




  render() {
    const { formValues, initialValues, templateConfigsList, selectTemplate, templateConfigsById, templateConfigsObById, handleSubmit, change, zuhe, tagstatusSelection } = this.props;
    const { selectedTagMat } = this.state;
    const values = formValues && formValues.values ? formValues.values : {}
    // const list = this.props.selector.filter(key => key.combinationTagType == 'SINGLE_TAG' || key.enterpriseDomain.codeSourceType == 'PLATFORM_SHORT_CODE').map((item) => { return { value: item.id, text: item.name, historyTagTemplateConfig: item.historyTagTemplateConfig } });
    // const list = this.props.selector.filter(key => key.combinationTagType == 'SINGLE_TAG' && key.enterpriseDomain.codeSourceType == 'PLATFORM_STANDARD' || key.enterpriseDomain.codeSourceType == 'PLATFORM_SHORT_CODE').map((item) => { return { value: item.id, text: item.name, historyTagTemplateConfig: item.historyTagTemplateConfig } });
    const list = this.props.selector.filter(key => (key.combinationTagType == 'SINGLE_TAG' || key.enterpriseDomain.codeSourceType == 'PLATFORM_SHORT_CODE') && key.enterpriseDomain.codeSourceType != 'CODE_IMPORT').map((item) => { return { value: item.id, text: item.name, wwAppId: item.wwAppId ? item.wwAppId : "", historyTagTemplateConfig: item.historyTagTemplateConfig } });
    return (

      <div>
        {!zuhe ?
          <div className="step0">

            <FormGroupField
              name="tagMatId"
              component={RadioButtonGroup}
              list={selectTemplate}
              onChange={(value) => {
                this.setState({
                  selectedTagMat: value
                })
                change('templateConfigId', null);
              }}
              validate={[this.requiredByTagMatId]}
            />

            {
              (values.tagMatId !== '' || initialValues.tagMatId !== '') &&
              <FormGroupField
                name="templateConfigId"
                component={RadioGroup}
                list={templateConfigsById[values.tagMatId || initialValues.tagMatId] || []}
                validate={[this.requiredByTemplateConfigId]}

              />
            }
            {this.getDescriptionFile(templateConfigsObById, values.tagMatId, values.templateConfigId) &&
              <iframe className="pdf-block" src={this.getDescriptionFile(templateConfigsObById, values.tagMatId, values.templateConfigId)} />
            }
            <div className="line" ></div>
            <div className="button-block">
              <Button className="weak-button" onClick={this.canCancel} >取消</Button>  <Button className="button-margin" onClick={handleSubmit(this.next)} >下一步</Button>
              {(this.state.confirmStatus) && <TablePopConfirm
                strongText={'印刷规格暂未完成，确认离开吗？'}
                text={'离开后编辑内容不做保存，请谨慎操作'}
                styles={{ marginTop: -165 }}   // height: 154,
                button={['确定', '取消']}
                onOk={this.cancel}
                onCancel={() => { this.setState({ confirmStatus: false }) }}
              />}
            </div>
          </div>
          :
          <div className="step0">
            <Tip style={{ marginTop: 20 }} hasClose={false} type="info">
              {values.combinationTagType == 'SAME_LEVEL_TAG' ? '同级码为一个物体上多个二维码，同级关系二维码之间在生成码后自动建立对应关系，每个二维码有独立身份ID。' :
                '层级码为一个物体的包装关系的二维码，层级关系二维码之间在生成码后自动建立对应关系，每个二维码有独立身份ID，上级一般为包装箱，下级一般为商品，具体请以实际场景为准。'}
            </Tip>
            <FormGroupField
              // disabled={isEdit}
              name="combinationTagType"
              // label="活动类型"
              list={[
                {
                  text: '同级码',
                  value: 'SAME_LEVEL_TAG'
                },
                {
                  text: '层级码',
                  value: 'MULTI_LEVEL_TAG'
                }
              ]}
              component={RadioButtonGroup}
            />
            {values.combinationTagType == 'SAME_LEVEL_TAG' ?
              <FormGroupList>
                <FormGroupField
                  label='同级关系码数量设置'
                  name="conditionKeys"
                  component={RadioGroup}
                  type="text"
                  list={[
                    { text: '2个', value: '2' },
                    { text: '3个', value: '3' },
                  ]}
                // validate={validateName2}
                // placeholder="请输入"
                />

                <FormGroupField
                  name="name1"
                  label='第1个码规格'
                  placeholder="请选择"
                  component={NewSelect}
                  style={{ width: 330 }}
                  list={list}
                  onChange={(v) => {
                    this.setState({ selecttype: { ...this.state.selecttype, name1: list.filter(key => key.value == v)[0] } })
                    change('name2', '')
                    change('name3', '')
                  }}
                  validate={[this.requiredByname1]}
                />
                <FormGroupField
                  name="name2"
                  label='第2个码规格'
                  placeholder="请选择"
                  component={NewSelect}
                  style={{ width: 330 }}
                  list={list.map((item) => {
                    if (item.value == values.name1) {
                      return { ...item, disabled: true }
                    }
                    let a = this.state.selecttype.name1.historyTagTemplateConfig;
                    let b = item.historyTagTemplateConfig;
                    if (a && b) {
                      if (a.facedsData && b.facedsData) {
                        if (JSON.parse(a.facedsData)[0].level == JSON.parse(b.facedsData)[0].level) {
                          if (JSON.parse(a.facedsData)[0].position == 'A' && (JSON.parse(b.facedsData)[0].position == 'B' || JSON.parse(b.facedsData)[0].position == 'C')) {
                            return { ...item, disabled: false }
                          }
                          else if (JSON.parse(a.facedsData)[0].position == 'B' && JSON.parse(b.facedsData)[0].position == 'C') {

                            return { ...item, disabled: false }
                          }
                          else {
                            return { ...item, disabled: true }
                          }
                        } else {
                          return { ...item, disabled: true }
                        }
                      } else {
                        return { ...item, disabled: true }
                      }
                    }
                    else {
                      return { ...item, disabled: true }
                    }
                  }
                  )}
                  validate={[this.requiredByname1]}
                  disabled={!values.name1}
                  onChange={(v) => {
                    this.setState({ selecttype: { ...this.state.selecttype, name2: list.filter(key => key.value == v)[0] } })
                    change('name3', '')
                  }}
                />
                {values.conditionKeys == 2 ? "" : <FormGroupField
                  name="name3"
                  label='第3个码规格'
                  placeholder="请选择"
                  style={{ width: 330 }}
                  component={NewSelect}
                  list={list.map((item) => {
                    if (item.value == values.name1) {
                      return { ...item, disabled: true }
                    }
                    if (item.value == values.name2) {
                      return { ...item, disabled: true }
                    }

                    let a = this.state.selecttype.name2.historyTagTemplateConfig;
                    let b = item.historyTagTemplateConfig;
                    if (a && b) {
                      if (a.facedsData && b.facedsData) {
                        if (JSON.parse(a.facedsData)[0].level == JSON.parse(b.facedsData)[0].level) {
                          if (JSON.parse(a.facedsData)[0].position == 'B' && JSON.parse(b.facedsData)[0].position == 'C') {
                            return { ...item, disabled: false }
                          }
                          else {
                            return { ...item, disabled: true }
                          }
                        } else {
                          return { ...item, disabled: true }
                        }
                      } else {
                        return { ...item, disabled: true }
                      }
                    }
                    else {
                      return { ...item, disabled: true }
                    }
                  }
                  )}
                  validate={[this.requiredByname1]}
                  disabled={!values.name2}
                  onChange={(v) => {
                    this.setState({ selecttype: { ...this.state.selecttype, name3: list.filter(key => key.value == v)[0] } })
                  }}
                />
                }
                {/* <FormGroupField
                  name="tagInitStatus"
                  label='码初始环节状态'
                  placeholder="请选择"
                  component={Select}
                  list={tagstatusSelection}
                /> */}
              </FormGroupList>
              :
              <FormGroupList>
                <FormGroupField
                  className='no-group-addon'
                  label='层级关系的二维码比例设置'
                  name="count"
                  component={Input}
                  type="text"
                  validate={[this.requiredByInput, this.max99999, validation.isNumber, this.min1]}
                  addonBefore={<span>1：</span>}
                />
                <FormGroupField
                  // className='no-group-addon'
                  label='码包文件中码排序规则'
                  name="multiCodeAsc"
                  component={NewSelect}
                  style={{ width: 330 }}
                  list={[
                    {
                      text: '箱码在前瓶码在后',
                      value: '-1',
                    },
                    {
                      text: '瓶码在前箱码在后',
                      value: true
                    }
                  ]}
                />



                <FormGroupField
                  name="name2_1"
                  label="箱码规格"
                  placeholder="请选择"
                  style={{ width: 330 }}
                  component={NewSelect}
                  list={list.map((item) => {

                    let b = item.historyTagTemplateConfig;
                    if (b) {
                      if (b.facedsData) {
                        if (JSON.parse(b.facedsData)[0].level != 'FIRST_LAYER') {
                          return { ...item, disabled: false }
                        }
                        else {
                          return { ...item, disabled: true }
                        }
                      } else {
                        return { ...item, disabled: true }
                      }
                    } else {
                      return { ...item, disabled: true }
                    }
                  })
                  }

                  onChange={(v) => {
                    this.setState({ selecttype2: { ...this.state.selecttype2, name1: list.filter(key => key.value == v)[0] } })
                    change('name2_2', '')
                    change('name2_3', '')
                    change('name2_4', '')
                  }}
                  validate={[this.requiredByname2]}
                />
                <FormGroupField
                  name="batchs"
                  label="文件数设置"
                  component={FileCount}
                // required
                // validate={[requiredBatchs]}
                // onChange={(v) => this.handleTagCountChange(v)}
                />
                <FormGroupField
                  name="name2_2"
                  label={values.batchs == 1 ? '瓶规格' : '第1个码规格'}
                  placeholder="请选择"
                  style={{ width: 330 }}
                  component={NewSelect}
                  list={list.map((item) => {
                    if (item.value == values.name2_1) {
                      return { ...item, disabled: true }
                    }
                    let a = this.state.selecttype2.name1.historyTagTemplateConfig;
                    let b = item.historyTagTemplateConfig;
                    if (a && b) {
                      if (a.facedsData && b.facedsData) {
                        if ((JSON.parse(a.facedsData)[0].level == 'THIRD_LAYER' && (JSON.parse(b.facedsData)[0].level == 'SECOND_LAYER' || JSON.parse(b.facedsData)[0].level == 'FIRST_LAYER'))
                          || (JSON.parse(a.facedsData)[0].level == 'SECOND_LAYER' && JSON.parse(b.facedsData)[0].level == 'FIRST_LAYER')) {

                          // if (JSON.parse(a.facedsData)[0].position == 'A' && (JSON.parse(b.facedsData)[0].position == 'B' || JSON.parse(b.facedsData)[0].position == 'C')) {
                          //   return { ...item, disabled: false }
                          // }
                          // else if (JSON.parse(a.facedsData)[0].position == 'B' && JSON.parse(b.facedsData)[0].position == 'C') {

                          return { ...item, disabled: false }
                          // }
                          // else {
                          // return { ...item, disabled: true }
                          // }
                        } else {
                          return { ...item, disabled: true }
                        }
                      } else {
                        return { ...item, disabled: true }
                      }
                    }
                    else {
                      return { ...item, disabled: true }
                    }
                  }
                  )}
                  disabled={!values.name2_1}
                  onChange={(v) => {
                    this.setState({ selecttype2: { ...this.state.selecttype2, name2: list.filter(key => key.value == v)[0] } })

                    change('name2_3', '')
                    change('name2_4', '')
                  }}
                  validate={[this.requiredByname1]}
                />
                {values.batchs >= 2 ? <FormGroupField
                  name="name2_3"
                  label='第2个码规格'
                  placeholder="请选择"
                  style={{ width: 330 }}
                  component={NewSelect}
                  disabled={!values.name2_2}
                  onChange={(v) => {
                    this.setState({ selecttype2: { ...this.state.selecttype2, name3: list.filter(key => key.value == v)[0] } })

                    change('name4', '')
                  }}
                  validate={[this.requiredByname1]}
                  list={list.map((item) => {
                    if (item.value == values.name2_1) {
                      return { ...item, disabled: true }
                    }
                    if (item.value == values.name2_2) {
                      return { ...item, disabled: true }
                    }
                    let a = this.state.selecttype2.name2.historyTagTemplateConfig;
                    let b = item.historyTagTemplateConfig;
                    if (a && b) {
                      if (a.facedsData && b.facedsData) {
                        if (JSON.parse(a.facedsData)[0].level == JSON.parse(b.facedsData)[0].level) {
                          if (JSON.parse(a.facedsData)[0].position == 'A' && (JSON.parse(b.facedsData)[0].position == 'B' || JSON.parse(b.facedsData)[0].position == 'C')) {
                            return { ...item, disabled: false }
                          }
                          else if (JSON.parse(a.facedsData)[0].position == 'B' && JSON.parse(b.facedsData)[0].position == 'C') {

                            return { ...item, disabled: false }
                          }
                          else {
                            return { ...item, disabled: true }
                          }
                        } else {
                          return { ...item, disabled: true }
                        }
                      } else {
                        return { ...item, disabled: true }
                      }
                    }
                    else {
                      return { ...item, disabled: true }
                    }
                  }
                  )}


                /> : ""}
                {values.batchs >= 3 ? <FormGroupField
                  name="name2_4"
                  label='第3个码规格'
                  style={{ width: 330 }}
                  placeholder="请选择"
                  component={NewSelect}
                  disabled={!values.name2_3}
                  list={list.map((item) => {
                    if (item.value == values.name2_1) {
                      return { ...item, disabled: true }
                    }
                    if (item.value == values.name2_2) {
                      return { ...item, disabled: true }
                    }
                    if (item.value == values.name2_3) {
                      return { ...item, disabled: true }
                    }

                    let a = this.state.selecttype2.name3.historyTagTemplateConfig;
                    let b = item.historyTagTemplateConfig;
                    if (a && b) {
                      if (a.facedsData && b.facedsData) {
                        if (JSON.parse(a.facedsData)[0].level == JSON.parse(b.facedsData)[0].level) {
                          if (JSON.parse(a.facedsData)[0].position == 'B' && JSON.parse(b.facedsData)[0].position == 'C') {
                            return { ...item, disabled: false }
                          }
                          else {
                            return { ...item, disabled: true }
                          }
                        } else {
                          return { ...item, disabled: true }
                        }
                      } else {
                        return { ...item, disabled: true }
                      }
                    }
                    else {
                      return { ...item, disabled: true }
                    }
                  }
                  )}
                  validate={[this.requiredByname1]}
                /> : ""
                }
                {/* <FormGroupField
                  name="tagInitStatus"
                  label='码初始环节状态'
                  placeholder="请选择"
                  component={Select}
                  list={tagstatusSelection}
                /> */}
              </FormGroupList>
            }
            <div className="line" ></div>
            <div className="button-block">
              <Button className="weak-button" onClick={this.canCancel} >取消</Button>  <Button className="button-margin" onClick={handleSubmit(this.next)} >下一步</Button>
              {(this.state.confirmStatus) && <TablePopConfirm
                strongText={'印刷规格暂未完成，确认离开吗？'}
                text={'离开后编辑内容不做保存，请谨慎操作'}
                styles={{ marginTop: -165 }}   // height: 154,
                button={['确定', '取消']}
                onOk={this.cancel}
                onCancel={() => { this.setState({ confirmStatus: false }) }}
              />}
            </div>
          </div>
        }
      </div>

    )
  }
}

let CreateCodePrintingConfigStep0Form = reduxForm({
  form: 'CreateCodePrintingConfigStep0Form', // a unique identifier for this form
  enableReinitialize: true,
})(CreateCodePrintingConfigStep0)

CreateCodePrintingConfigStep0Form = connect(
  state => ({
    initialValues: state.codePrintingConfig.createStep0,
    step0: state.codePrintingConfig.createStep0,
    step1: state.codePrintingConfig.createStep1,
    templateConfigsList: state.codePrintingConfig.templateConfigsList,
    selectTemplate: state.codePrintingConfig.selectTemplate,
    templateConfigsById: state.codePrintingConfig.templateConfigsById,
    templateConfigsObById: state.codePrintingConfig.templateConfigsObById,
    formValues: state.form.CreateCodePrintingConfigStep0Form,
    selector: state.codePrintingConfig.selector,
    tagstatusSelection: state.links.tagstatusSelections1 || [],
  }),
  {
    //码状态
    tagstatusSelections: tagstatusSelections.REQUEST,
    loadTemplateConfigsList: loadTemplateConfigsList.REQUEST,
    loadSelect: loadSelect.REQUEST,
    setCreateStep,
    setCurrentStep,
  }
)(CreateCodePrintingConfigStep0Form)

CreateCodePrintingConfigStep0Form = withRouter(CreateCodePrintingConfigStep0Form);

export default CreateCodePrintingConfigStep0Form;